import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAdminComponent } from './admin/main-admin/main-admin.component';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { MainComponent } from './website/main/main.component';
import { MainComponent as MainImobiliariaComponent } from './imobiliaria/main/main.component';
import { ImobiliariaGuard } from './guards/imobiliaria.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    loadChildren: () =>
      import('./website/website.module').then((m) => m.WebsiteModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: MainAdminComponent,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'imobiliaria',
    loadChildren: () =>
      import('./imobiliaria/imobiliaria.module').then(
        (m) => m.ImobiliariaModule
      ),
    component: MainImobiliariaComponent,
    canActivate: [ImobiliariaGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [GuestGuard],
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
