import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

export class ViaCEP {
  cep: string = '';
  logradouro: string = '';
  complemento: string = '';
  bairro: string = '';
  localidade: string = '';
  uf: string = '';
  unidade: string = '';
  ibge: string = '';
  gia: string = '';
}

export class MetaTags {
  nome: string = 'Ahead Sistemas | Sistemas para reduzir custos e ganhar tempo';
  descricaoSimples: string =
    'Sistemas para reduzir custos e ganhar tempo. Controle de XML, Indicadores(BI), Cotação Eletronica, Auditoria Fiscal e muito mais. Veja nossas solucoes';
  imagem: string = 'https://aheadsistemas.com.br/assets/avatar.png';
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  moment;
  emailRegex: RegExp;
  urlRegex: RegExp;
  config = {
    placeholder: '',
    tabsize: 2,
    height: '340px',
    uploadImagePath: '/api/upload',
    tooltip: false,
    toolbar: [
      ['misc', ['codeview']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['para', ['style', 'ul', 'paragraph']],
      ['insert', ['link', 'picture', 'video']],
    ],
  } as any;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private api: ApiService,
    private _meta: Meta,
    private _title: Title,
    private toastrService: ToastrService
  ) {
    moment.locale('pt-BR');
    this.moment = moment;
    this.emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
  }

  openSnackBar(message: string, title: string = '', isError: boolean = false) {
    if (isError) {
      this.toastrService.error(title, message);
    } else {
      this.toastrService.success(title, message);
    }
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object)
          .values(control.controls)
          .forEach((c: any) => this.formMarkAllTouched(c));
      }
    });
  }

  convertToSlug(str: string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâãèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  viaCep(cep: string) {
    return this.http.get<ViaCEP>(`https://viacep.com.br/ws/${cep}/json/`);
  }

  buscarEstados() {
    return this.api.get(`/site/uf/buscar`);
  }

  buscarCidades(siglaUf: string, filtro: string = '%') {
    return this.api.get(
      `/site/Cidade/Buscar?siglaUf=${siglaUf}&filtro=${filtro}`
    );
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  getYoutubeId(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  updateTags(metatags?: MetaTags) {
    const data = {
      ...new MetaTags(),
      ...metatags,
    };
    const shareUrl: string = `https://immobile.digital`;
    this._title.setTitle(data.nome);
    this._meta.updateTag({ name: 'title', content: data.nome });
    this._meta.updateTag({ name: 'subject', content: data.nome });
    this._meta.updateTag({
      name: 'description',
      content: data.descricaoSimples,
    });
    this._meta.updateTag({ name: 'url', content: shareUrl });
    this._meta.updateTag({ name: 'image', content: data.imagem });
    this._meta.updateTag({ property: 'og:title', content: data.nome });
    this._meta.updateTag({
      property: 'og:description',
      content: data.descricaoSimples,
    });
    this._meta.updateTag({ property: 'og:url', content: shareUrl });
    this._meta.updateTag({ property: 'og:image', content: data.imagem });
    this._meta.updateTag({
      property: 'og:image:secure_url',
      content: data.imagem,
    });
    this._meta.updateTag({ property: 'og:image:alt', content: data.nome });
    this._meta.updateTag({ name: 'twitter:title', content: data.nome });
    this._meta.updateTag({
      name: 'twitter:description',
      content: data.descricaoSimples,
    });
    this._meta.updateTag({ name: 'twitter:url', content: shareUrl });
    this._meta.updateTag({ name: 'twitter:image', content: data.imagem });
    this._meta.updateTag({ name: 'twitter:image:src', content: data.imagem });
  }

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURI(text)
    );
    element.setAttribute('download', filename + '.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
