import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

interface RouteProps {
  name: string;
  icon: string;
  route?: string;
  group?: RouteProps[];
  openGroup?: boolean;
}

@Component({
  selector: 'header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  search: string = '';
  pages: RouteProps[] = [];

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router
  ) {
    this.buildPages();
  }

  ngOnInit() {}

  goTo(page: any) {
    if (page.route) {
      this.router.navigate([page.route]).then(() => {
        this.global.menuOpen = false;
      });
    } else {
      page.openGroup = !page.openGroup;
    }
  }

  logout() {
    this.global.menuOpen = false;
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  onSearchChange(ev) {
    if (!ev) {
      this.buildPages();
    }
  }

  buildPages() {
    this.pages = [
      { name: 'Home', icon: 'fas fa-home', route: '/admin' },
      {
        name: 'Cadastros',
        icon: 'fas fa-list-ul',
        group: [
          {
            name: 'Tipos de Empreendimento',
            icon: 'fas fa-border-style',
            route: '/admin/tipos-empreendimento',
          },
          {
            name: 'Imobiliárias',
            icon: 'fas fa-store-alt',
            route: '/admin/imobiliarias',
          },
          {
            name: 'Empreendimentos',
            icon: 'fas fa-building',
            route: '/admin/empreendimentos',
          },
          { name: 'Planos', icon: 'fas fa-tags', route: '/admin/planos' },
        ],
        openGroup: false,
      },
      {
        name: 'Usuários',
        icon: 'fas fa-users',
        group: [
          {
            name: 'Aplicativo',
            icon: 'fas fa-user-friends',
            route: '/admin/usuarios-app',
          },
          {
            name: 'Imobiliárias/Corretores',
            icon: 'fas fa-user-tie',
            route: '/admin/usuarios-imobiliaria',
          },
          {
            name: 'Adminstradores',
            icon: 'fas fa-user-shield',
            route: '/admin/usuarios',
          },
        ],
        openGroup: false,
      },
      {
        name: 'Configurações',
        icon: 'fas fa-cogs',
        group: [
          {
            name: 'Geral',
            icon: 'fas fa-cog',
            route: '/admin/configuracao-geral',
          },
          {
            name: 'E-mail',
            icon: ' fas fa-paper-plane',
            route: '/admin/configuracao-email',
          },
          {
            name: 'Contato',
            icon: 'fas fa-phone-alt',
            route: '/admin/contato-geral',
          },
        ],
        openGroup: false,
      },
    ];
  }

  getContrast = (hexcolor) => {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split('')
        .map(function (hex) {
          return hex + hex;
        })
        .join('');
    }

    // Convert to RGB value
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    return yiq >= 128 ? 'black' : 'white';
  };
}
