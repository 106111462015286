import { GlobalService } from './global.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UsuarioAdmin } from '../models/usuario-admin.model';
import { UsuarioImobiliaria } from '../models/usuario-imobiliaria.model';
import { Router } from '@angular/router';

export type AuthType = 'gerenciador' | 'imobiliaria';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl = '';
  isRecuperarSenha: boolean = false;
  token: string = '';
  user: any = {};
  type: AuthType = 'gerenciador';

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public http: HttpClient,
    public global: GlobalService,
    public router: Router,
  ) { }

  login(email, password) {
    return this.http.get(`${this.global.apiUrl}/${this.type}/${this.type === 'gerenciador' ? 'usuarioAdmin' : 'usuarioImobiliaria'}/autenticar`, {
      params: {
        usuario: email,
        senha: password
      }
    });
  }

  getAuthorizationHeader() {
    return new HttpHeaders().append('Authorization', 'Basic ' + this.token);
  }

  getUserStorage() {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem('user_immobile') as any);
    }
  }

  setToken(token) {
    if (!token) {
      return;
    }

    this.token = token;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('token_immobile', this.token);
    }
  }

  setAuthType(type: AuthType) {
    if (!type) return;

    this.type = type;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('auth_type_immobile', this.type);
    }
  }

  setUser(user) {
    if (!user) {
      return;
    }

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('user_immobile', JSON.stringify(user));
      this.user = this.getUserStorage();
    }
  }

  getUser(user) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.global.apiUrl}/${this.type}/${this.type === 'gerenciador' ? 'usuarioAdmin' : 'usuarioImobiliaria'}/buscar?email=${user.email}`)
        .subscribe((res: any) => {
          resolve(res);
        }, (e) => {
          reject(e);
        });
    })
  }

  logout() {
    this.token = '';
    this.user = new UsuarioAdmin();
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
    this.router.navigate(['/']);
  }

  isLoggedIn() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (!this.token) {
      if (localStorage.getItem('token_immobile')) {
        this.setToken(localStorage.getItem('token_immobile'));
        this.user = this.getUserStorage();
      } else {
        return false;
      }
    }

    return true;
  }

  forgotPassword(user) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.global.apiUrl}/${this.type}/${this.type === 'gerenciador' ? 'usuarioAdmin' : 'usuarioImobiliaria'}/esqueceuSenha?email=${user.email}`)
        .subscribe((res: any) => {
          resolve(res);
        }, (e) => {
          reject(e);
        });
    })
  }
}
