import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

interface RouteProps {
  name: string,
  icon: string,
  route?: string;
  group?: RouteProps[]
  openGroup?: boolean,
  canActive: boolean,
}

@Component({
  selector: 'header-imobiliaria',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  search: string = '';
  pages: RouteProps[] = []

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.buildPages();
  }

  goTo(page: any) {
    if (page.route) {
      this.router.navigate([page.route]).then(() => {
        this.global.menuOpen = false;
      });
    } else {
      page.openGroup = !page.openGroup;
    }
  }

  logout() {
    this.global.menuOpen = false;
    this.auth.logout();
    this.router.navigate(['/login/imobiliaria']);
  }

  onSearchChange(ev) {
    if (!ev) {
      this.buildPages();
    }
  }

  buildPages() {
    this.pages = [
      { name: 'Home', icon: 'fas fa-home', route: '/imobiliaria', canActive: true },
      { name: 'Imobiliaria', icon: 'fas fa-store', route: '/imobiliaria/perfil', canActive: this.auth.user.tipo === 'A' ? true : false },
      { name: 'Empreendimentos', icon: 'fas fa-building', route: '/imobiliaria/empreendimentos', canActive: true },
      { name: 'Usuários', icon: 'fas fa-user-shield', route: '/imobiliaria/usuarios', canActive: this.auth.user.tipo === 'A' ? true : false },
    ]
  }

}
