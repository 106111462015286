import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, AuthType } from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _auth: AuthService,
    private _router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {

    const authType = localStorage.getItem('auth_type_immobile') as AuthType;
    if (this._auth.isLoggedIn() && authType === 'gerenciador') {
      return true;
    } else if (this._auth.isLoggedIn()) {
      return this._router.parseUrl('/imobiliaria');
    } else {
      return this._router.parseUrl('/login/gerenciador');
    }
  }
}