import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from './services/api.service';
import { AuthService, AuthType } from './services/auth.service';
import { HelperService } from './services/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public auth: AuthService,
    public api: ApiService,
    public helper: HelperService
  ) {
    this.api.httpStatus.subscribe((status) => {
      if (status === 401) this.auth.logout();
    });
    this.api.httpError.subscribe((error) =>
      this.helper.openSnackBar('Erro na chamada', error, true)
    );
    if (isPlatformBrowser(this.platformId)) {
      this.auth.token = localStorage.getItem('token_immobile');
      this.auth.user = this.auth.getUserStorage();
      const authType = localStorage.getItem('auth_type_immobile');
      if (authType) {
        this.auth.type = localStorage.getItem('auth_type_immobile') as AuthType;
      }
    }
  }
}
