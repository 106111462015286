import { Injectable } from '@angular/core';

export const empreendimentoStatus = [
  { label: 'Aberto', value: 'A' },
  { label: 'Vendido', value: 'V' },
  { label: 'Locado', value: 'L' },
  { label: 'Reservado', value: 'R' },
  { label: 'Retirado pelo proprietario', value: 'RP' },
];

export const empreendimentoTiposOferta = [
  { label: 'Venda', value: 'V' },
  { label: 'Locação', value: 'L' },
  { label: 'Em construção', value: 'C' },
];

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  apiUrl: string = 'https://api.immobile.digital';
  imageUrl: string = 'https://api.immobile.digital/';
  menuOpen: boolean = false;

  constructor() {}

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  getEmpreendimentoStatusLabel(value: string): string {
    const _ = {
      A: 'Aberto',
      V: 'Vendido',
      L: 'Locado',
      R: 'Reservado',
      RP: 'Retirado pelo proprietario',
    };

    return _[value] ?? 'Status desconhecido';
  }

  getEmpreendimentoTipoOfertaLabel(value: string): string {
    const _ = {
      V: 'Venda',
      L: 'Locação',
      C: 'Em construção',
    };

    return _[value] ?? 'Tipo de oferta desconhecido';
  }
}
