import { Autenticacao } from './autenticacao.model';

export class UsuarioAdmin {
  id = 0;
  nome = '';
  email = '';
  senha = '';
  re_senha? = '';
  situacao = 'A';
  autenticacao?: Autenticacao;
  // aux
  loading? = false;
}
