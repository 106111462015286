import 'hammerjs';
import 'mousetrap';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module';
import { MaterialModule } from './material/material.module';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxMaskModule } from 'ngx-mask';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ToastrModule } from 'ngx-toastr';
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { APP_DATE_FORMATS } from './date.adapter';
import {
  CurrencyMaskInputMode,
  NgxCurrencyModule,
  CurrencyMaskConfig,
} from 'ngx-currency';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ImobiliariaGuard } from './guards/imobiliaria.guard';
registerLocaleData(localePt);

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { SharedModule } from './shared/shared.module';
import { PixelModule } from 'ngx-pixel';
import { NgxDropzoneModule } from 'ngx-dropzone';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: false,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
} as CurrencyMaskConfig;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgxSummernoteModule.forRoot(),
    NgxMaskModule.forRoot(),
    MaterialModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    PixelModule.forRoot({ pixelId: '1369777953536716', enabled: true }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    GalleryModule.forRoot({
      shortcuts: ['ctrl+s', 'meta+s'],
      disableSsrWorkaround: true,
    }),
    NgxPageScrollCoreModule.forRoot({
      duration: 300,
    }),
    NgxPageScrollModule,
    SharedModule,
    NgxDropzoneModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    AuthGuard,
    GuestGuard,
    ImobiliariaGuard,
  ],
  exports: [
    FormsModule,
    NgxSummernoteModule,
    NgxMaskModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    GalleryModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    NgxDropzoneModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
